@import '../../mixins';

.quick-buy {
  &__title {
    margin-bottom: rem(37);

    width: 100%;
    display: flex;

    @include mediaBigDesktop {
      margin-bottom: big(37);
    }

    @include mediaLaptop {
      margin-bottom: rem(30);
    }

    @include mediaMobile {
      margin-bottom: rem(25);
    }
  }

  &__sum {
    margin-bottom: rem(40);
    padding-bottom: rem(20);

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: end;

    border-bottom: solid 1px var(--stroke-dark);

    @include mediaBigDesktop {
      margin-bottom: big(40);
      padding-bottom: big(20);

      border-bottom-width: big(1);
    }

    @include mediaLaptop {
      margin-bottom: rem(30);
      padding-bottom: rem(15);
    }

    @include mediaTablet {
      padding-bottom: rem(20);
    }

    @include mediaMobile {
      margin-bottom: rem(15);
      padding-bottom: rem(15);
    }

    & .text {
      color: var(--text-dark-secondary);

      & .quick-buy {
        &__strong {
          color: var(--text-dark-primary);
          font-size: rem(25);
          font-weight: 600;

          @include mediaBigDesktop {
            font-size: big(25);
          }

          @include mediaLaptop {
            font-size: rem(20);
          }

          @include mediaTablet {
            font-size: rem(16);
          }
        }
      }
    }
  }

  &__submit {
    display: flex;
    flex-direction: row;
    gap: rem(20);

    @include mediaBigDesktop {
      gap: big(20);
    }

    @include mediaLaptop {
      gap: rem(15);
    }

    @include mediaMobile {
      flex-direction: column;
    }

    & .text-input {
      width: 100%;
      flex-grow: 1;
    }

    & .button {
      flex-shrink: 0;

      @include mediaMobile {
        width: 100%;
      }
    }
  }
}
